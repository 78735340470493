// export const API_URL = 'http://backend.gol.test/api'
// export const API_URL = 'https://api.gardenoflife.thewickfirm.com/api'
export const API_URL = 'https://api.gardenoflife.thewickfirm.com/api'

export const CANCELLED_PAYMENT = -1;
export const SUCCESS_PAYMENT = 1;
export const PENDING_PAYMENT = 0;

export const RENTAL_DURATION = [
    '',
    'Daily',
    'Weekly',
    'Monthly',
    'Annual',
]

export const PRODUCT_TYPE_OFFER = 4

